@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  height: 100vh !important;
  background-color: #f5f7f5 !important;
}

* {
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-family: "Lato", sans-serif !important;
}

img {
  max-width: 100%;
  height: 100%;
}

h1 {
  margin: 0 !important;
  line-height: initial !important;
}

.container-website {
  width: 100%;
  margin: 0 auto;
  max-width: 1760px;
  padding: 0 80px;
}

.flex-centrado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fx {
  display: flex;
}

.fx-ai-c {
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 !important;
}

.header-login {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
}

.header-login .row {
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.header-login .block-logo {
  margin: 0;
  padding: 0;
}

.header-login .block-logo img {
  width: 60px;
  border-radius: 50%;
  margin-right: 12px;
}

.h3-txt-login {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.pt-blue {
  font-size: 26px;
  color: #1d91f5;
  line-height: 0;
}

.section-banner-login {
  position: relative;
  background-image: url("./components/media/fondo.jpg");
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.section-banner-login::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    50deg,
    rgba(39, 42, 55, 1) 48%,
    rgba(39, 42, 55, 0.6) 100%
  );
}

.section-banner-login .content-block {
  position: relative;
  background-color: transparent;
  padding: 30px;
  border-radius: 30px;
  max-width: 400px;
  width: 100%;
  box-shadow: none;
}

.section-banner-login .content-block h4 {
  margin-bottom: 30px;
  color: #fff;
  font-weight: bold;
  text-align: left;
  font-size: 38px;
  line-height: normal;
}

.section-banner-login .content-block h4::after {
  content: ".";
  color: #1d91f5;
  font-size: 58px;
  line-height: 0;
}

.section-banner-login .css-11lq3yg-MuiGrid-root {
  gap: 21px;
}

.input-login {
  background-color: #323645;
  border-radius: 18px;
  padding: 10px 56px 16px 24px !important;
}

.input-login label {
  position: relative;
  color: #6e717b;
  font-size: 16px;
  font-weight: bold;
  transform-origin: bottom left;
}

.input-login .MuiInput-root {
  position: initial;
  margin: 0;
}

.input-login .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
}

.input-login .svg-inline--fa.fa-w-20 {
  width: 20px;
  margin-right: -2px;
}

.input-login .svg-inline--fa.fa-w-18 {
  width: 19px;
  margin-right: -2px;
}

.input-login input {
  font-size: 16px;
  line-height: initial;
  color: #fff;
  padding: 0;
}

.input-login .css-ittuaa-MuiInputAdornment-root {
  position: absolute;
  right: 24px;
  top: 50%;
  margin: 0;
  height: auto;
  transform: translateY(-50%);
}

.btn-login {
  margin-top: 40px !important;
  background-color: #1d91f5 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 18px !important;
  padding: 12px 32px !important;
  font-size: 16px !important;
}

.btn-login:hover {
  background-color: #0e7ee0 !important;
}

/* animation input login */
.css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
  top: 3px !important;
  right: 3px !important;
  bottom: 3px !important;
  left: 3px !important;
  box-shadow: 0 0 0 2px inset #1e8cec;
  border-bottom: 0 !important;
  border-radius: 16px;
  transform: none !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease !important;
}

.css-ghsjzk-MuiInputBase-root-MuiInput-root.Mui-focused:after {
  opacity: 1;
  visibility: visible;
}

.css-ghsjzk-MuiInputBase-root-MuiInput-root:before {
  display: none;
}

.css-1q25q95-MuiPaper-root-MuiAppBar-root {
  margin-left: 275px !important;
  width: calc(100% - 275px) !important;
}

.css-q9wbw4-MuiDrawer-docked {
  width: 275px !important;
}

.css-q9wbw4-MuiDrawer-docked .MuiDrawer-paper {
  width: 275px !important;
}

/* DASHBOARD NAV ICONOS */
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: 1px solid #dbe1f2 !important;
}

.css-2dum1v-MuiDrawer-docked {
  width: calc(80px + 1px) !important;
}

.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
  width: calc(80px + 1px) !important;
  background-color: #f9faff;
  overflow: overlay;
}

.css-h4y409-MuiList-root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 18px 15px !important;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root {
  padding-left: 10.49px !important;
  border-radius: 14px !important;
  background-color: #eceef5 !important;
  overflow: hidden;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root .icon-svg-nav {
  width: 30px;
  min-width: 30px;
}

.css-10hburv-MuiTypography-root {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #7c859c;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
  background-color: #e9efff !important;
  box-shadow: 0 0 0 2px #1f5eff inset;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected
  .icon-svg-nav
  g {
  fill: #1f5eff;
  stroke: #1f5eff;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected
  .icon-svg-nav {
  color: #1f5eff !important;
  fill: #1f5eff;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected
  .css-10hburv-MuiTypography-root {
  color: #1f5eff;
}

.css-cveggr-MuiListItemIcon-root {
  align-items: center;
}

.icon-svg-nav {
  fill: #7c859c;
}

.icon-svg-nav g {
  fill: #7c859c;
  stroke: #7c859c;
}

/* HEADER */
.cont-dash-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 21px;
  padding: 18px 15px;
}

.cont-dash-title .flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cont-dash-title .block-img {
  width: 46px;
  min-width: 46px;
  height: 46px;
  background-color: #1f5eff;
  border-radius: 12px;
}

.cont-dash-title .block-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.cont-dash-title .cont-des h5 {
  font-size: 20px;
  line-height: initial;
  font-weight: bold;
  color: rgb(9, 9, 9);
}

.cont-dash-title .cont-des h6 {
  margin-top: 4px;
  font-size: 14px;
  line-height: initial;
  color: #7d7d7d;
}

.css-19fk7ms-MuiPaper-root-MuiAppBar-root {
  margin-left: 276px !important;
  width: calc(100% - 276px) !important;
  border-bottom: 1px solid #dbe1f2;
}

.cont-icon-left-header {
  position: relative;
  width: 31px;
  min-width: 31px;
  margin: 0 !important;
  padding: 0;
}

.cont-icon-left-header::before {
  content: "";
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cont-icon-left-header .i {
  position: relative;
  font-size: 16px;
  margin-left: 2px;
}

.cont-icon-left-header:hover {
  padding: 0 !important;
  background-color: transparent !important;
}

.cont-icon-left-header:hover .i {
  color: #1f5eff !important;
}

.cont-icon-left-header:hover::before {
  background-color: #e9efff;
}

.css-1tsuurj-MuiPaper-root-MuiAppBar-root {
  border-bottom: 1px solid #dbe1f2;
}

.css-1t29gy6-MuiToolbar-root {
  justify-content: end;
  min-height: 76px !important;
}

.block-profile-header {
  display: flex;
  align-items: center;
  gap: 15px;
}

.block-profile-header .block-img {
  width: 42px;
  min-width: 42px;
  height: 42px;
}

.block-profile-header .block-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 9px;
}

.block-profile-header .cont-des {
  background-color: transparent;
  margin: 0;
  padding: 0;
  text-align: left;
}

.block-profile-header .cont-des .fx-title {
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: space-between;
  font-size: 14px;
}

.block-profile-header .cont-des .fx-title h5 {
  font-size: inherit;
  line-height: initial;
  font-weight: bold;
  color: inherit;
}

.block-profile-header .cont-des .fx-title .i {
  color: inherit;
  font-size: 13px;
}

.block-profile-header .cont-des h6 {
  margin-top: 4px;
  font-size: 13px;
  line-height: initial;
  color: #7d7d7d;
}

/* CONTENIDO */
.css-1u2mxbp {
  min-height: 76px !important;
}

.css-zxdg2z {
  /* background-color: #f9faff; */
  background-color: #f5f7f9;
  /* height: 100vh; */
}

.section-list-home .css-mhc70k-MuiGrid-root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.section-list-home .css-mhc70k-MuiGrid-root > .MuiGrid-item {
  width: 100%;
  max-width: 100%;
}

.section-list-home .block-list-icon {
  /* background-color: #3761ec; */
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 12px -6px rgb(0 0 0 / 6%);
  transition: all 0.3s ease;
}

.section-list-home .block-list-icon:hover {
  background-color: #3761ec;
  box-shadow: 0 10px 30px -11px #3761ec;
}

.section-list-home .block-list-icon .flex-cont {
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
}

.gp14 {
  gap: 14px;
}

.section-list-home .block-list-icon .flex-cont .block-text {
  text-align: left;
}

.section-list-home .block-list-icon .flex-cont .br-icon {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 14px;
  background-color: #ebf0ff;
}

.section-list-home .block-list-icon:hover .flex-cont .br-icon {
  background-color: #fff;
}

.section-list-home .block-list-icon .flex-cont .br-icon .i {
  font-size: 23px;
  color: #3761ec;
}

.section-list-home .block-list-icon .flex-cont .block-text p {
  color: #090909;
  font-size: 24px;
  line-height: initial;
  font-weight: bold;
  margin: 0;
  transition: color 0.3s ease;
}

.section-list-home .block-list-icon .flex-cont .block-text h6 {
  color: #aaaaaa;
  font-size: 15px;
  line-height: initial;
  margin-top: 3px;
  transition: color 0.3s ease;
}

.section-list-home .block-list-icon:hover .flex-cont .block-text p,
.section-list-home .block-list-icon:hover .flex-cont .block-text h6,
.section-list-home .block-list-icon:hover .flex-cont .icon-block-flecha .i {
  color: #fff;
}

.section-list-home .block-list-icon .flex-cont .icon-block-flecha {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.section-list-home .block-list-icon .flex-cont .icon-block-flecha .i {
  font-size: 14px;
  color: #3761ec;
}

.contenido-home .cont-statistcs {
  margin-top: 3rem;
  padding: 28px;
  background-color: #fff;
  border-radius: 21px;
  box-shadow: 0 4px 12px -6px rgb(0 0 0 / 6%);
}

.contenido-home .cont-statistcs .flex-statistcs-txt {
  display: flex;
  align-items: center;
  gap: 21px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2.8rem;
}

.contenido-home .cont-statistcs .flex-statistcs-txt .h5 {
  font-size: 20px;
  line-height: initial;
  color: #aaa;
}

button {
  outline: none;
  border-style: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}

.css-1oqqzyl-MuiContainer-root {
  margin-top: 1rem;
}

/* SECURITY */
.section-security .css-1wf8b0h-MuiTabs-flexContainer {
  justify-content: left;
}

.section-security .css-1ujnqem-MuiTabs-root {
  width: fit-content;
  align-items: center;
  flex: auto;
  padding: 6px;
  border-radius: 18px;
  background-color: #fff;
  box-shadow: 0 4px 12px -6px rgb(0 0 0 / 6%);
}

.section-security .cont-nav .css-jpln7h-MuiTabs-scroller {
  overflow: initial !important;
}

.section-security
  .cont-nav
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #f4f5f9;
  box-shadow: 0 4px 12px -6px rgb(0 0 0 / 6%);
  color: #1f5eff;
}

.section-security .cont-nav .btn {
  padding: 12px 12px;
  border-radius: 14px;
  font-size: 14px;
  font-weight: bold;
  text-transform: initial;
  color: #97a1b5;
  min-height: initial;
}

.section-security .cont-nav .btn:hover {
  color: #1f5eff;
}

.section-security .cont-nav .css-1aquho2-MuiTabs-indicator {
  display: none;
}

.section-security .css-19kzrtu {
  margin-top: 2rem;
  padding: 0;
}

.section-security .css-19r6kue-MuiContainer-root {
  padding: 0;
}

.section-security .cont-info {
  background-color: #fff;
  padding: 28px;
  border-radius: 21px;
  box-shadow: 0 4px 12px -6px rgb(0 0 0 / 6%);
}

.section-security .cont-info .title-flex {
  display: flex;
  align-items: center;
}

.section-security .cont-info .title-flex h5 {
  text-align: left;
}

.section-security .cont-info .title-flex .option {
  display: flex;
  align-items: center;
}

.section-security .cont-info .title-flex .option .subt-select {
  display: flex;
  align-items: center;
  gap: 12px;
}

.section-security .cont-info .title-flex .option .subt-select h6 {
  font-size: 14px;
  line-height: initial;
  color: #7d7d7d;
}

.section-security
  .cont-info
  .title-flex
  .option
  .subt-select
  .div-slt
  .css-1480iag-MuiInputBase-root-MuiInput-root::before {
  display: none;
}

.section-security
  .cont-info
  .title-flex
  .option
  .subt-select
  .div-slt
  .css-1480iag-MuiInputBase-root-MuiInput-root::after {
  display: none;
}

.section-security
  .cont-info
  .title-flex
  .option
  .subt-select
  .div-slt
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background-color: transparent;
}

.section-security
  .cont-info
  .title-flex
  .option
  .subt-select
  .div-slt
  .css-1480iag-MuiInputBase-root-MuiInput-root {
  width: 128px;
}

.section-security
  .cont-info
  .title-flex
  .option
  .subt-select
  .div-slt
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
  padding-top: 6px;
  padding-right: 28px;
  padding-bottom: 6px;
  padding-left: 18px;
  border: 1px solid #d4d4d4;
  border-radius: 21px;
  color: rgb(16, 81, 85);
}

.section-security .cont-info .title-flex .option .subt-select .div-slt svg {
  color: #bcbcbc;
  right: 7px;
}

.section-security .cont-info .input-width .css-nnbavb {
  display: none;
}

.section-security .cont-info .input-width {
  margin: 1rem 0;
  width: 100%;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  line-height: initial !important;
  border-radius: 12px !important;
}

.css-makoee-MuiInputAdornment-root {
  height: initial;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13px 14px 13px 10px !important;
  height: initial !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.section-security .cont-info .input-width svg {
  color: #1e8cec;
}

.css-1z4p5c-MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.css-1z4p5c-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.css-1z4p5c-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.css-1z4p5c-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar
  .css-levciy-MuiTablePagination-displayedRows {
  margin: 0;
}

@media only screen and (max-width: 1300px) {
  .section-list-home .css-mhc70k-MuiGrid-root {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 1035px) {
  .section-list-home .css-mhc70k-MuiGrid-root {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1128px) {
  .container-website {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 743px) {
  .section-list-home .css-mhc70k-MuiGrid-root {
    grid-template-columns: 1fr;
  }

  .container-website {
    padding: 0 24px;
  }

  .section-banner-login {
    justify-content: center;
  }

  .section-banner-login .content-block {
    padding: 0;
  }
}



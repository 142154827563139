.active {
  text-decoration: none;
  color: #1c54b2;
  font-size: 15px;
  font-weight: bold;
}
.inactive {
  text-decoration: none;
  color: inherit;
  font-size: 15px;
}

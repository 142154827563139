/* Espinoza Estilos */

@font-face {
	font-family: 'Agency FB', sans-serif;
	src: url('https://fonts.cdnfonts.com/css/agency-fb');
}

.tableHeader {
	font-weight: bold;
	color: #2979ff;
	font-size: 16px;
}

.pagareHeader {
	font-family: 'Agency FB', sans-serif !important;
	color: '#212121' !important;
	font-weight: bold !important;
	font-size: 10 !important;
}
.pagareP {
	font-family: 'Agency FB', sans-serif !important;
	color: '#212121' !important;
	text-align: justify;
	/* font-size: 10 !important; */
}
strong {
	/* font-weight: 900 !important; */
	/* font-style: italic; */
	font-family: 'Agency FB Bold', sans-serif !important;
	/* color: '#212121' !important; */
	/* text-align: justify; */
	/* font-size: 30 !important; */
}
